import { Box, Card, CardActionArea, Grid, Paper, Typography, Stack } from "@mui/material"
import Image from "next/image"
import React from "react"

import plusActive from "assets/img/icons/arrow-hover.svg"
import plus from "assets/img/icons/arrow.svg"
import inch from "assets/img/services/1inch.svg"
import coinbase from "assets/img/services/coinbase.svg"
import fireblocks from "assets/img/services/fireblocks.svg"
import gnosis from "assets/img/services/gnosis.svg"
import ledger from "assets/img/services/ledger.svg"
import metamask from "assets/img/services/metamask.svg"

import { useStyles } from "./styles"

const accessBlocks = [
  {
    title: "MetaMask",
    copy: "Use Flux through one of the most popular and longest-standing Ethereum wallets",
    icon: metamask,
    url: "https://metamask.io/",
  },
  {
    title: "Coinbase Wallet",
    copy: "Earn yield at Flux through this non-custodial crypto wallet developed by Coinbase",
    icon: coinbase,
    url: "https://www.coinbase.com/wallet",
  },
  // {
  //   title: '1inch',
  //   copy: 'Swap from any asset to fStables and back through this exchange aggregator',
  //   icon: inch,
  //   url: 'https://app.1inch.io/#/1/simple/swap/ETH/DAI',
  // },
  {
    title: "Fireblocks",
    copy: "Lend and borrow at Flux directly from this institutional MPC custody solution",
    icon: fireblocks,
    url: "https://www.fireblocks.com/",
  },
  {
    title: "Ledger",
    copy: "Store your fTokens in your own hardware wallet",
    icon: ledger,
    url: "https://www.ledger.com/ledger-live",
  },
  {
    title: "Gnosis Safe",
    copy: "Manage your treasury at this multi-signature storage solution popular with DAOs",
    icon: gnosis,
    url: "https://safe.global/",
  },
]

const AccessSection = () => (
  <>
    <Box mt={"96px"}>
      <Typography variant="h3">Access Flux</Typography>
      <Typography variant="h5" style={{ opacity: 0.7, maxWidth: "32em" }} mt={2}>
        Flux Finance is a decentralized protocol and can be accessed from a variety of applications and services.
      </Typography>
      <Grid container mt={5} spacing={3}>
        {accessBlocks.map((step, i) => (
          <Grid key={i} item xs={12} sm={6} lg={4} xl={3}>
            <Paper elevation={0} sx={{ height: "100%", position: "relative", p: 0 }}>
              <Box
                sx={{
                  p: 5,
                  pb: { xs: 5, sm: 20 },
                  height: "100%",
                  cursor: "pointer",
                  ".arrow": { background: `url(${plus}) no-repeat center center` },
                  "&:hover .arrow": { background: `url(${plusActive}) no-repeat center center` },
                }}
                onClick={() => window.open(step.url, "_blank")}
              >
                <Stack
                  spacing={{ xs: 5, sm: 3 }}
                  direction={{ xs: "row", sm: "column" }}
                  alignItems={{ xs: "center", sm: "flex-start" }}
                >
                  <Box
                    sx={{
                      minWidth: { xs: "48px", sm: "80px" },
                      maxWidth: { xs: "48px", sm: "80px" },
                      height: { xs: "48px", sm: "80px" },
                    }}
                  >
                    <Image src={step.icon} alt={step.title} style={{ width: "100%" }} />
                  </Box>
                  <Stack spacing={1}>
                    <Typography variant="h5">{step.title}</Typography>
                    <Typography
                      maxWidth={{ xs: "auto", sm: "16em" }}
                      sx={{
                        opacity: 0.7,
                        fontSize: (t) => ({
                          xs: t.typography.body1.fontSize,
                          sm: t.typography.h6.fontSize,
                        }),
                      }}
                    >
                      {step.copy}
                    </Typography>
                  </Stack>
                </Stack>
                <Box
                  sx={{
                    display: { xs: "none", sm: "block" },
                    height: "50px",
                    width: "50px",
                    backgroundSize: "contain",
                    position: "absolute",
                    right: 16,
                    bottom: 16,
                  }}
                  className="arrow"
                />
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  </>
)

export default AccessSection
