import { Box } from "@mui/material"
import React from "react"
import AccessSection from "components/Landing/Access"
import { AboveFoldHero, BelowFoldHero } from "components/Landing/Hero"
import MediaSection from "components/Landing/Media"

const LandingPage = () => {
  return (
    <div>
      <Box
        sx={{
          minHeight: { xs: "auto", lg: "calc(100vh - 86px)" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <AboveFoldHero />
      </Box>
      <BelowFoldHero />
      <AccessSection />
      <MediaSection />
    </div>
  )
}

export default LandingPage
