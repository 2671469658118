import { Box, Grid, Link, Paper, Theme, Typography, Stack } from "@mui/material"
import { Button } from "components"
import Image from "next/image"
import React from "react"

import certora from "assets/img/auditors/certora-icon.svg"
import code4rena from "assets/img/auditors/code4rena-icon.svg"
import frame from "assets/img/auditors/frame.svg"
import openZeppelin from "assets/img/auditors/open-zeppelin-icon.svg"
import tob from "assets/img/auditors/tob.svg"
import connector from "assets/img/connector.svg"
import bounties from "assets/img/icons/bounties.svg"
import external from "assets/img/icons/external-light.svg"
import ousg from "assets/img/icons/ousg-light.svg"
import shield from "assets/img/icons/shield.svg"
import blackrock from "assets/img/partners/blackrock.svg"
import clearstreet from "assets/img/partners/clearstreet.svg"
import coinbase from "assets/img/partners/coinbase.svg"
import nav from "assets/img/partners/nav.svg"

const productSteps = [
  {
    title: "Lend Stablecoins",
    copy: "Lend your stablecoins to Flux to immediately start accumulating interest",
  },
  {
    title: "Receive fStables",
    copy: "Receive fStables representing the right to reclaim stablecoins, plus interest",
  },
  {
    title: "Use fStables in DeFi",
    copy: "Transfer fStables anywhere to leverage the benefits of other protocols",
  },
]

const AboveFoldHero = () => (
  <>
    <Box mb={{ xs: 15, md: 3 }}>
      <Typography variant="h1" maxWidth="20em">
        Lend and borrow stablecoins against tokenized US Treasuries
      </Typography>
      <Grid container mt={5} spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              color: "#fff",
              position: "relative",
              overflow: "hidden",
              height: "100%",
              background: "#4F38F7",
              display: "flex",
              flexDirection: "column",
              minHeight: { xs: "auto", xl: "18em" },
            }}
          >
            <Typography variant="h4" mb={{ xs: "48px", lg: "32px" }} maxWidth={{ xs: "80%", lg: "100%" }}>
              <Box
                sx={{
                  position: { xs: "absolute", lg: "static" },
                  right: (theme: Theme) => theme.spacing(3),
                  top: (theme: Theme) => theme.spacing(5),
                  width: { xs: "32px", lg: "36px" },
                  float: { xs: "none", lg: "left" },
                  marginRight: "10px",
                  marginTop: "-3px",
                }}
              >
                <Image src={ousg} style={{ width: "100%" }} alt="ousg" />
              </Box>
              Safe and Stable Collateral
            </Typography>
            <Stack direction={{ xs: "column", lg: "row" }} spacing={{ xs: "48px", lg: 10 }}>
              <Typography variant="h6" color="inherit" flex={1}>
                The only collateral accepted at Flux is OUSG, a tokenized US Treasury from Ondo Finance.{" "}
                <Link
                  href="https://ondo.finance/ousg"
                  target="_blank"
                  color="inherit"
                  sx={{ textDecoration: "none", mt: 5, display: "block" }}
                >
                  Explore OUSG <Image src={external} alt="follow link" />
                </Link>
              </Typography>
              <Typography variant="h6" color="inherit" flex={1}>
                OUSG currently invests largely in the tokenized BlackRock USD Institutional Digital Liquidity Fund
                (BUIDL), with the remainder in BlackRock's FedFund (TFDXX), bank deposits, and stablecoins.
              </Typography>
            </Stack>
            <div style={{ flex: 1 }} />
            <Stack
              direction={{ xs: "column", lg: "row" }}
              gap={{ xs: 3, lg: 3, xl: 15 }}
              mt={"56px"}
              alignItems={{ xs: "flex-start", lg: "center" }}
              justifyContent="space-between"
              flexWrap="wrap"
              maxHeight={{ xs: "8em", lg: "auto" }}
            >
              <Link
                href="https://www.ishares.com/us/products/239466/ishares-short-treasury-bond-etf"
                target="_blank"
                sx={{
                  transition: "opacity .3s ease-in-out",
                  display: "block",
                  flex: 127,
                  "&:hover": { opacity: 0.7 },
                }}
              >
                <Image
                  src={blackrock}
                  width={1}
                  height={1}
                  style={{ width: "100%", height: "auto" }}
                  alt={"blackrock"}
                />
              </Link>
              <Link
                href="https://clearstreet.io/"
                target="_blank"
                sx={{
                  transition: "opacity .3s ease-in-out",
                  display: "block",
                  flex: 139,
                  "&:hover": { opacity: 0.7 },
                }}
              >
                <Image
                  src={clearstreet}
                  width={1}
                  height={1}
                  style={{ width: "100%", height: "auto" }}
                  alt={"clearstreet"}
                />
              </Link>
              <Link
                href="https://prime.coinbase.com/"
                target="_blank"
                sx={{
                  transition: "opacity .3s ease-in-out",
                  display: "block",
                  flex: 145,
                  "&:hover": { opacity: 0.7 },
                }}
              >
                <Image src={coinbase} width={1} height={1} style={{ width: "100%", height: "auto" }} alt={"coinbase"} />
              </Link>
              <Link
                href="https://www.navconsulting.net/"
                target="_blank"
                sx={{
                  transition: "opacity .3s ease-in-out",
                  display: "block",
                  flex: 76,
                  "&:hover": { opacity: 0.7 },
                }}
              >
                <Image src={nav} width={1} height={1} style={{ width: "100%", height: "auto" }} alt={"nav"} />
              </Link>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              position: "relative",
              overflow: "hidden",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              minHeight: { xs: "auto", xl: "18em" },
            }}
          >
            <Typography variant="h4" mb={{ xs: "48px", lg: "32px" }} maxWidth={{ xs: "80%", lg: "100%" }}>
              <Box
                sx={{
                  position: { xs: "absolute", lg: "static" },
                  right: (theme: Theme) => theme.spacing(3),
                  top: (theme: Theme) => theme.spacing(5),
                  width: { xs: "32px", lg: "36px" },
                  float: { xs: "none", lg: "left" },
                  marginRight: "10px",
                  marginTop: "-3px",
                }}
              >
                <Image src={shield} style={{ width: "100%" }} alt="secure" />
              </Box>
              The Most Secure Stablecoin Yield
            </Typography>
            <Stack direction={{ xs: "column", lg: "row" }} height="100%" gap={{ xs: 0, lg: 3, xl: 10 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Typography variant="h6" color="inherit">
                  Battle-tested code
                </Typography>
                <Typography variant="h6" color="inherit" sx={{ opacity: 0.7, flex: 1 }}>
                  Flux is a fork of Compound v2, which has been around since 2019 and audited by industry leaders.
                </Typography>
                <Stack direction="row" spacing={5} mt={{ xs: 3, xl: "56px" }} mb={{ xs: "48px", lg: 0 }}>
                  <Link
                    sx={{
                      transition: "opacity .3s ease-in-out",
                      "&:hover": { opacity: 0.7 },
                    }}
                    href="https://www.trailofbits.com/"
                    target="_blank"
                  >
                    <Image src={tob} style={{ width: "100%" }} alt={"trail of bits"} />
                  </Link>
                  <Link
                    sx={{
                      transition: "opacity .3s ease-in-out",
                      "&:hover": { opacity: 0.7 },
                    }}
                    href="https://openzeppelin.com/"
                    target="_blank"
                  >
                    <Image src={openZeppelin} style={{ width: "100%" }} alt={"openZeppelin"} />
                  </Link>
                  <Link
                    sx={{
                      transition: "opacity .3s ease-in-out",
                      "&:hover": { opacity: 0.7 },
                    }}
                    href="https://www.certora.com/"
                    target="_blank"
                  >
                    <Image src={certora} style={{ width: "100%" }} alt={"certora"} />
                  </Link>
                </Stack>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: { xs: "auto", md: "100%" },
                }}
              >
                <Typography variant="h6" color="inherit">
                  Audits and bug bounty
                </Typography>
                <Typography
                  variant="h6"
                  color="inherit"
                  sx={{ flex: { xs: "none", lg: 1 } }}
                  mb={{ xs: 3, lg: "56px" }}
                >
                  <span style={{ opacity: 0.7 }}>
                    Changes to the Compound open source codebase relate only to permissioning and have been audited by{" "}
                  </span>
                  <Link
                    href="https://code4rena.com/reports/2023-01-ondo/"
                    target="_blank"
                    sx={{ textDecoration: "none" }}
                  >
                    <Image src={code4rena} width={20} alt={"code4rena"} />{" "}
                    <span style={{ color: "#56189E" }}>code4rena.</span>
                  </Link>
                </Typography>
                <Button
                  variant="secondary"
                  style={{ maxWidth: "16em", marginBottom: "2px" }}
                  onClick={() => window.open("https://immunefi.com/bounty/fluxfinance/", "_blank")}
                >
                  <Image src={bounties} style={{ width: "20px", margin: "0 7px 0 -5px" }} alt={"immunify"} /> $550,000
                  Bug Bounty
                  <Image
                    src={external}
                    style={{ width: "20px", margin: "0 -5px 0 5px", filter: "invert(1)" }}
                    alt={"external"}
                  />
                </Button>
              </Box>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  </>
)
const BelowFoldHero = () => (
  <>
    <Box>
      <Paper
        elevation={0}
        sx={{
          height: "100%",
          minHeight: "23em",
          background: "#16171A",
          color: "#fff",
          position: "relative",
        }}
      >
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ mb: 1 }}>
              How Lending Works
            </Typography>
          </Grid>
          <Box
            sx={{
              position: "absolute",
              top: "114px",
              left: "50px",
              width: "90%",
              display: { xs: "none", md: "block" },
            }}
          >
            <Image src={connector} style={{ width: "100%" }} alt="connector" />
          </Box>
          {productSteps.map((step, i) => (
            <Grid key={i} item xs={12} md={4}>
              <Stack spacing={1}>
                <Paper
                  variant="outlined"
                  sx={{
                    borderRadius: "80px !important",
                    width: "64px",
                    height: "64px",
                    lineHeight: "64px",
                    textAlign: "center",
                    p: 0,
                    fontSize: (theme) => theme.typography.h4.fontSize,
                    borderColor: "#fff",
                    background: "#16171A",
                    color: "#fff",
                    zIndex: 2,
                    mb: 2,
                  }}
                >
                  {i + 1}
                </Paper>
                <Typography variant="h4">{step.title}</Typography>
                <Typography variant="h6" color="#AAABAE">
                  {step.copy}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Box>
  </>
)

export { AboveFoldHero, BelowFoldHero }
