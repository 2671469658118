import {
  Box,
  Card,
  CardActionArea,
  Grid,
  Paper,
  Typography,
  getContrastRatio,
  getLuminance,
  Stack,
} from "@mui/material"
import Image from "next/image"
import React from "react"

import first from "assets/img/blog/1.png"
import second from "assets/img/blog/2.png"
import third from "assets/img/blog/3.png"
import flux_is_live from "assets/img/blog/flux-is-live.png"
import discord from "assets/img/icons/discord-solid.svg"
import forum from "assets/img/icons/forum-solid.svg"
import github from "assets/img/icons/github-solid.svg"
import telegram from "assets/img/icons/telegram-solid.svg"
import twitter from "assets/img/icons/twitter-solid.svg"
import vote from "assets/img/icons/vote-solid.svg"
import {
  FLUX_DAO_URL,
  FLUX_DISCORD_URL,
  FLUX_FORUM_URL,
  FLUX_GITHUB_URL,
  FLUX_TELEGRAM_URL,
  FLUX_TWITTER_URL,
} from "../../constants"

const socialBlocks = [
  {
    title: "Forum",
    icon: forum,
    url: FLUX_FORUM_URL,
    color: "#FFFFFF",
  },
  {
    title: "Vote",
    icon: vote,
    url: FLUX_DAO_URL,
    color: "#242528",
  },
  {
    title: "Twitter",
    icon: twitter,
    url: FLUX_TWITTER_URL,
    color: "#00ACEE",
  },
  {
    title: "Discord",
    icon: discord,
    url: FLUX_DISCORD_URL,
    color: "#5865F2",
  },
  {
    title: "Telegram",
    icon: telegram,
    url: FLUX_TELEGRAM_URL,
    color: "#0088CC",
  },
  {
    title: "Github",
    icon: github,
    url: FLUX_GITHUB_URL,
    color: "#333333",
  },
]
const blogPosts = [
  {
    title: "Zap-in and Zap-out on Cowswap",
    copy: "Thanks to Seasolver by @yearnfi, @CoWSwap users can buy and sell fTokens easily and efficiently.",
    image: second,
    url: "https://twitter.com/FluxDeFi/status/1655579404527861761",
  },
  {
    title: "Reserve Enables Flux Finance fTokens",
    copy: "Flux is thrilled to announce that Reserve has enabled fUSDC and fDAI to be used as collateral as backing for its RTokens.",
    image: third,
    url: "https://blog.fluxfinance.com/reserve-enables-flux-finance-ftokens/",
  },
  {
    title: "Flux Finance is Live",
    copy: "We are thrilled to announce that Flux Finance is live, bringing yield from US Treasuries to investors everywhere.",
    image: flux_is_live,
    url: "https://blog.fluxfinance.com/flux-finance-is-live/",
  },
]

const MediaSection = () => (
  <>
    <Box mt={"96px"}>
      <Typography variant="h3">Latest News</Typography>

      <Grid container mt={5} spacing={3}>
        {blogPosts.map((post, i) => (
          <Grid key={i} item xs={12} sm={6} lg={4}>
            <Paper
              elevation={0}
              sx={{
                height: "100%",
                position: "relative",
                p: 0,
                cursor: "pointer",
                overflow: "hidden",
              }}
              onClick={() => window.open(post.url, "_blank")}
            >
              <Stack spacing={3}>
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <Image src={post.image} alt={post.title} style={{ width: "100%", height: "100%" }} />
                </Box>
                <Box sx={{ p: 5, pt: 2, pb: 10 }}>
                  <Typography variant="h4" mb={1}>
                    {post.title}
                  </Typography>
                  <Typography variant="h6" sx={{ opacity: 0.7 }}>
                    {post.copy}
                  </Typography>
                </Box>
              </Stack>
            </Paper>
          </Grid>
        ))}
        <Grid item xs={12} mt={"96px"} mb={5}>
          <Typography variant="h3">Join the community</Typography>
          <Typography variant="h5" style={{ opacity: 0.7, maxWidth: "32em" }} mt={2}>
            A community of ONDO token holders are responsible for guiding the future direction of Flux through the Ondo
            DAO.
          </Typography>
        </Grid>
        {socialBlocks.map((step, i) => (
          <Grid key={i} item xs={12} sm={4} md={3} lg={2}>
            <Paper
              elevation={0}
              sx={{
                height: "100%",
                position: "relative",
                background: step.color,
                p: 0,
                transition: "opacity .3s ease-in-out",
                "&:hover": { opacity: 0.84 },
              }}
            >
              <Box sx={{ p: 5, cursor: "pointer" }} onClick={() => window.open(step.url, "_blank")}>
                <Stack
                  spacing={3}
                  direction={{ xs: "row", sm: "column" }}
                  alignItems={{ xs: "center", sm: "flex-start" }}
                >
                  <Box
                    sx={{
                      width: { xs: "32px", sm: "46px" },
                      height: { xs: "32px", sm: "46px" },
                    }}
                  >
                    <Image src={step.icon} alt={step.title} style={{ width: "100%" }} />
                  </Box>
                  <Typography variant="h5" color={getLuminance(step.color) > 0.5 ? "#000" : "#FFF"}>
                    {step.title}
                  </Typography>
                </Stack>
              </Box>
            </Paper>
          </Grid>
        ))}
        <Grid item xs={12} mt={1} />
      </Grid>
    </Box>
  </>
)

export default MediaSection
